<template>
    <div
        id="ruleBuilder"
        data-cy="ruleBuilder"
    >
        <div
            v-if="!isLoading"
            class="row"
        >
            <div class="col-12 mt-3">
                <BRAddEditForm
                    ref="addEditForm"
                    data-cy="addEditForm"
                    :read-only="ruleBuilderMode === 'view'"
                    :selected-rule="selectedRule"
                    :selected-version="selectedVersion"
                    :tooltip-wording="tooltipWording"
                />

                <RouterView />
            </div>
        </div>

        <div
            v-else
            class="loading d-flex justify-content-center align-content-center mt-5"
            data-cy="loading"
        >
            <div class="content text-center my-auto">
                <FontAwesomeIcon
                    icon="spinner"
                    size="3x"
                    pulse
                />

                <h3>Loading rule...</h3>
            </div>
        </div>
    </div>
</template>

<script>
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import utils from '@imt/vue-toolbox/src/utils';
    import {mapActions, mapMutations} from 'vuex';

    import BRAddEditForm from '@/components/BRAddEditForm.vue';
    import {tooltips} from '@/lang';

    export default {
        name: 'BRRuleBuilder',
        components: {
            BRAddEditForm,
        },
        mixins: [toastsMixin],
        data() {
            return {
                isLoading: false,
                ruleBuilderMode: 'add',
                selectedRule: {},
                selectedVersion: {},
                tooltipWording: tooltips.en,
            };
        },
        async created() {
            await this.init();
        },
        methods: {
            async init() {
                this.isLoading = true;
                if (this.$route.name.includes('add')) {
                    this.ruleBuilderMode = 'add';
                    this.isLoading = false;

                    return;
                } else if (this.$route.name.includes('edit')) {
                    this.ruleBuilderMode = 'edit';
                } else if (this.$route.name.includes('view')) {
                    this.ruleBuilderMode = 'view';
                }

                try {
                    this.selectedRule = await this.fetchRule(this.$route.params.ruleId);
                    this.selectedVersion = await this.fetchVersionDetails({
                        ruleId: this.$route.params.ruleId,
                        versionId: this.$route.params.versionId,
                    });

                    this.SET_EXPRESSION_BUILDER_DATA({field: 'expressionGroups', data: this.selectedVersion.expressionGroups});
                } catch (error) /* istanbul ignore next */ {
                    utils.console.log(error);

                    this.error('This rule was not found or does not exist', 'Rule Not Found');
                    await this.$router.push({name: 'admin.rules.list', query: {...this.$route.query}});
                } finally {
                    this.isLoading = false;
                }
            },
            ...mapMutations('expressionBuilder', [
                'SET_EXPRESSION_BUILDER_DATA',
            ]),
            ...mapActions([
                'fetchRule',
                'fetchVersionDetails'
            ]),
        },
    };
</script>
